import { useFormikContext } from 'formik';
import { Button, Cascader, CascaderProps } from 'antd';
import { useState } from 'react';
import { BaseOptionType } from 'antd/es/cascader';
import { objectByString } from '../../../utility/Utils';
import { OptionGroupDto } from '../../../api/data-contracts';
import AppFormLabel from '../../components/forms/AppFormLabel';

interface Option {
  value?: string | number | null;
  label: React.ReactNode;
  children?: Option[];
  isLeaf?: boolean;
}

interface Props {
  databaseList: OptionGroupDto[];
}

function transformDatabaseOptions(backendData: OptionGroupDto[]) {
  return backendData.map(option => ({
    label: option.label,
    value: option.key,
    children: option.options?.map(child => ({
      ...child,
      value: child.value,
      label: child.label,
    })),
  }));
}

function DatabaseSettings({ databaseList }: Props) {
  const { values, setFieldValue } = useFormikContext();
  const [options] = useState<Option[]>(transformDatabaseOptions(databaseList));

  function onChange(value: BaseOptionType, e: any) {
    setFieldValue("databaseServer", value[0]);
    setFieldValue("databaseName", value[1]);
  };

  return (
    <>
      <div className="separator mb-4" />

      <div className="fv-row">
        <AppFormLabel label={"Database name & server"} required={true} />

        <Cascader size={"large"}
          value={[objectByString(values, "databaseServer"), objectByString(values, "databaseName")]}
          className='w-100' options={options} onChange={onChange} />
      </div>
    </>
  );
}

export default DatabaseSettings;
